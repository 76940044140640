const MapComponent = () => {
	return (
		<div style={{ width: "100%", height: "400px" }}>
			<iframe
				src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28261.853899229234!2d85.339818!3d27.694685!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb199a06c2eaf9%3A0xc5670a9173e161de!2sNew%20Baneshwor%2C%20Kathmandu%2044600!5e0!3m2!1sen!2snp!4v1724579322001!5m2!1sen!2snp"
				width="100%"
				height="400"
				style={{ border: 0 }}
				allowfullscreen=""
				loading="lazy"
				referrerpolicy="no-referrer-when-downgrade"
			></iframe>
		</div>
	);
};

export default MapComponent;
