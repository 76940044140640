import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useCookies } from "react-cookie";
import BASE_URL from "../../subcomponents/Config";
import { useNavigate, useParams } from "react-router-dom";
import { MdTimer } from "react-icons/md";
import { MdDiscount } from "react-icons/md";
import "../../Styles/Exam/exam.scss";
import Pagination from "../../admin/adminComponents/Pagination/Pagination";
import SearchBar from "../components/Search";
import {
	Box,
	CircularProgress,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from "@mui/material";
import CourseCategory from "../components/CourseCategory";
import WeeklyExamModal from "./WeeklyExam";
import useLoading from "../../Hooks/useLoading";
import Loading from "../components/Loading";

const Exam = () => {
	const { type } = useParams();
	const [cookies] = useCookies(["userToken"]);
	const [exams, setExams] = useState([]);
	const nav = useNavigate();
	const [searchError, setSearchError] = useState("");
	const [searching, setSearching] = useState(false);
	const { loading, showLoading, hideLoading } = useLoading();
	const [token, setToken] = useState(cookies.userToken || "");
	const [error, setError] = useState("");

	const [examId, setExamId] = useState(null);
	const [examName, setExamName] = useState("");
	const [examTime, setExamTime] = useState(0);
	const [examModel, setExamModel] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const itemsPerPage = 8;

	const [selectedOption, setSelectedOption] = useState("1");
	const [title, setTitle] = useState("");

	const [open, setOpen] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	useEffect(() => {
		setToken(cookies.userToken || "");
	}, [cookies]);

	useEffect(() => {
		GetExamData();
		window.scrollTo(0, 0);
	}, [currentPage, type, title]);
	useEffect(() => {
		GetExamData();
		setTitle("");
		window.scrollTo(0, 0);
	}, [type]);

	const GetExamData = async () => {
		let url = "";
		let options = {
			method: "GET",
		};
		if (token) {
			url = `${BASE_URL}/api/v1/exam/enrolledCoursesExam/${type}?courseTitle=${title}&page=${currentPage}&size=${itemsPerPage}`;
			options.headers = {
				Authorization: `Bearer ${token}`,
			};
		} else {
			url = `${BASE_URL}/api/v1/exam/exams/${type}/course?courseTitle=${title}&page=${currentPage}&size=${itemsPerPage}`;
		}
		showLoading();
		try {
			const response = await fetch(url, options);
			if (response.ok) {
				const allData = await response.json();
				setExams(allData.exams);
				setError("");
				setTotalPages(allData.totalPages);
				hideLoading();
			} else {
				const error = await response.json();
				setError(error.message);
				setExams([]);
				hideLoading();
			}
		} catch (error) {
			showLoading();
		}
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const handleExamClick = (id, hasAttended, name, time) => {
		if (token) {
			if (hasAttended === true) {
				nav(`/exam/result/${id}`);
			} else {
				setExamId(id);
				setExamName(name);
				setExamTime(time);
				setExamModel(true);
			}
		} else {
			nav("/login");
		}
	};

	const convertDecimalToTime = (decimalTime) => {
		const hours = Math.floor(decimalTime);
		const minutes = Math.round((decimalTime - hours) * 60);
		let timeString = `${hours} hr${hours !== 1 ? "s" : ""}`;
		if (minutes > 0) {
			timeString += ` ${minutes} min${minutes !== 1 ? "s" : ""}`;
		}
		return timeString;
	};

	const handleTakeMcqs = () => {
		window.scrollTo(0, 0);
		nav(`/exam/details/${examId}`);
		setExamModel(false);
	};

	const handleSearch = async (searchTerm) => {
		if (searchTerm === "") {
			GetExamData();
			setSearching(false);
		} else {
			setSearching(true);
			try {
				const res = await fetch(
					`${BASE_URL}/api/v1/exam/search/${type}?keyword=${searchTerm}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);
				if (!res.ok) {
					const error = await res.json();
					setSearchError(error.message);
				} else {
					const data = await res.json();
					setExams(data);
				}
			} catch (error) {
				console.log(error.message);
			}
		}
	};

	return (
		<>
			<Navbar />
			{loading ? <Loading /> : null}
			{!loading && (
				<div className="exam-main">
					<div className="search_header">
						{token ? (
							<div className="search">
								<SearchBar onSearch={handleSearch} />
								{searchError && (
									<div style={{ color: "red", fontSize: "0.8rem" }}>
										{searchError}
									</div>
								)}
							</div>
						) : (
							<div></div>
						)}
						<h3>Exams</h3>
						{type === "weekly" ? (
							<div className="weekly-exams">
								<span>Weekly Exams Date</span>
								<button onClick={handleOpen}>Course List</button>
							</div>
						) : null}
						<WeeklyExamModal open={open} handleClose={handleClose} />
					</div>
					<hr />

					<div className="exam-submain">
						<div>
							<CourseCategory
								title={title}
								selectedOption={selectedOption}
								setSelectedOption={setSelectedOption}
								setTitle={setTitle}
								type={type}
							/>
						</div>
						{error && (
							<div
								style={{
									color: "red",
									fontSize: "1.2rem",
									textAlign: "center",
								}}
							>
								{error}
							</div>
						)}
						<div className="exam-outer">
							{exams.map((exam) => (
								<div className="exam-inner" key={exam.examId}>
									<div className="exam-image">
										<img src={exam.imageUrl} alt="" />
									</div>
									<div className="exam-content">
										<h4 className="exam-name">{exam.examName}</h4>
										<div className="exam-courseTitle">{exam.courseTitle}</div>
										<div className="exam-bottom">
											<span>
												<MdTimer style={{ marginRight: "0.2rem" }} />
												{convertDecimalToTime(exam.totalTime)}
											</span>
											<span>
												<MdDiscount style={{ marginRight: "0.2rem" }} />
												{exam.totalQuestions} MCQs
											</span>
											<button
												onClick={() =>
													handleExamClick(
														exam.examId,
														exam.hasAttended,
														exam.examName,
														exam.totalTime
													)
												}
												style={{
													backgroundColor: examModel ? "white" : "#fd6a02",
												}}
											>
												{exam.hasAttended ? "View Result" : "Take MCQs"}
											</button>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
					{!searching && !error && (
						<Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={handlePageChange}
						/>
					)}
					<Dialog
						open={examModel}
						onClose={() => setExamModel(false)}
						aria-labelledby="exam-confirmation-dialog"
					>
						<DialogTitle style={{ textAlign: "center", fontWeight: "600" }}>
							Take MCQs
						</DialogTitle>
						<DialogContent>
							<div
								style={{
									fontSize: "1.1rem",
									padding: "0.5rem 1.8rem",
									textAlign: "justify",
								}}
							>
								You are about to start the {examName} exam. As soon as you click
								on start exam your exam will begin and you will have{" "}
								{convertDecimalToTime(examTime)} to complete and submit your
								exam.
								<div style={{ color: "darkred", marginTop: "0.8rem" }}>
									The exam will automatically get submitted once your total time
									is over.
								</div>
							</div>
							<div
								style={{
									fontSize: "1rem",
									color: "red",
									padding: "0.2rem 1rem",
									fontWeight: "500",
								}}
							>
								<span style={{ fontSize: "1.2rem" }}>Note: </span>
								Your Exam won't be submitted if you leave the page.
							</div>
						</DialogContent>
						<DialogActions
							style={{ marginRight: "2rem", marginBottom: "1rem" }}
						>
							<Button
								onClick={() => setExamModel(false)}
								sx={{
									marginRight: "2rem",
								}}
							>
								Cancel
							</Button>
							<Button
								onClick={handleTakeMcqs}
								color="primary"
								sx={{
									color: "white",
									backgroundColor: "#fd6a02",
									"&:hover": { backgroundColor: "darkorange" },
									padding: "0.4rem 1.5rem",
									borderRadius: "10px",
								}}
							>
								Start Exam
							</Button>
						</DialogActions>
					</Dialog>
				</div>
			)}
			<Footer />
		</>
	);
};

export default Exam;
