import React, { useEffect, useState, useRef } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../../Styles/Exam/exam.scss";
import { toast, ToastContainer } from "react-toastify";
import { Box, CircularProgress } from "@mui/material";
import useNavigationBlocker from "../../Hooks/useNavigationBlocker";
import useLoading from "../../Hooks/useLoading";
import Loading from "../components/Loading";

export default function GiveExam() {
	const { id } = useParams();
	const [cookies] = useCookies(["userToken"]);
	const [examData, setExamData] = useState({});
	const [questions, setQuestions] = useState([]);
	const [selectedAnswers, setSelectedAnswers] = useState([]);
	const [currentpage, setCurrentpage] = useState(1);
	const [totalpages, setTotalpages] = useState(1);
	const itemsperpage = 8;
	const nav = useNavigate();
	const { loading, showLoading, hideLoading } = useLoading();
	const [token, setToken] = useState(cookies.userToken || "");
	const [error, setError] = useState("");
	const [timeLeft, setTimeLeft] = useState(null);
	const [notSubmitted, setNotSubmitted] = useState(false);
	const initialLoad = useRef(true);

	const convertTimeToSeconds = (decimalTime) => {
		const hours = Math.floor(decimalTime);
		const minutes = (decimalTime - hours) * 60;
		return hours * 3600 + minutes * 60;
	};

	const formatTime = (seconds) => {
		const h = String(Math.floor(seconds / 3600)).padStart(2, "0");
		const m = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
		const s = String(seconds % 60).padStart(2, "0");
		return `${h}:${m}:${s}`;
	};

	useEffect(() => {
		if (timeLeft && timeLeft > 0) {
			const timer = setInterval(() => {
				setTimeLeft((prevTime) => prevTime - 1);
			}, 1000);

			return () => clearInterval(timer);
		} else if (timeLeft === 0) {
			handleMcqSubmit();
		}
	}, [timeLeft]);

	useEffect(() => {
		setToken(cookies.userToken || "");
	}, [cookies]);

	useEffect(() => {
		GetExamData();
		window.scrollTo(0, 0);
	}, [currentpage]);

	const GetExamData = async () => {
		showLoading();
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/exam/${id}/questions?page=${currentpage}&size=${itemsperpage}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (response.ok) {
				const allData = await response.json();
				setExamData(allData);
				setQuestions(allData.questions);
				setTotalpages(allData.totalPages);

				// Only set timeLeft on initial load
				if (initialLoad.current) {
					setTimeLeft(convertTimeToSeconds(allData.totalTime));
					initialLoad.current = false;
				}

				hideLoading();
			} else {
				const error = await response.json();
				setError(error.message);
				hideLoading();
			}
		} catch (error) {
			showLoading();
		}
	};

	const handleOptionChange = (questionId, optionIndex) => {
		setSelectedAnswers((prevSelectedAnswers) => {
			const existingAnswerIndex = prevSelectedAnswers.findIndex(
				(answer) => answer.questionId === questionId
			);

			if (existingAnswerIndex !== -1) {
				if (
					prevSelectedAnswers[existingAnswerIndex].selectedOption ===
					optionIndex
				) {
					return prevSelectedAnswers;
				}

				const updatedAnswers = [...prevSelectedAnswers];
				updatedAnswers[existingAnswerIndex] = {
					questionId: questionId,
					selectedOption: optionIndex,
				};
				return updatedAnswers;
			} else {
				return [
					...prevSelectedAnswers,
					{
						questionId: questionId,
						selectedOption: optionIndex,
					},
				];
			}
		});
	};

	const handleMcqSubmit = async (e) => {
		if (e) e.preventDefault();
		setNotSubmitted(true);
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/exam/${id}/submitAnswers`,
				{
					method: "POST",
					headers: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json",
					},
					body: JSON.stringify(selectedAnswers),
				}
			);

			if (response.ok) {
				const result = await response.json();
				window.scrollTo(0, 0);
				nav("/exam/result", { state: { result } });
				setNotSubmitted(false);
			} else {
				const error = await response.json();
				toast.error(error.message);
				setNotSubmitted(false);
			}
		} catch (error) {
			showLoading();
		}
	};

	useNavigationBlocker(
		notSubmitted,
		"You have unsaved changes, are you sure you want to leave?"
	);

	return (
		<>
			<ToastContainer position="top-center" autoClose={2000} />
			<Navbar />
			{loading ? <Loading /> : null}
			{error && <div className="error">{error}</div>}
			{!loading && (
				<main>
					<div className="examDetails-main">
						<div className="examDetails-topic">
							<h2>{examData.examName}</h2>
							<div className="examDetails-timer">
								<span className="examDetails-yourTimer">Your Timer: </span>
								{formatTime(timeLeft)}
							</div>
						</div>

						<form onSubmit={handleMcqSubmit}>
							<div className="examDetails-outer">
								{questions &&
									questions.map((question, index) => (
										<div
											className="examDetails-inner"
											key={question.questionId}
										>
											<div className="exam-question">
												{index + 1 + (currentpage - 1) * itemsperpage}.{" "}
												{question.question}
											</div>
											<div className="exam-options">
												{[
													question.option1,
													question.option2,
													question.option3,
													question.option4,
												].map((option, index) => (
													<div key={index} className="exam-option">
														<input
															type="radio"
															id={`${question.questionId}-${index}`}
															name={`question-${question.questionId}`}
															value={index + 1}
															checked={
																selectedAnswers.find(
																	(answer) =>
																		answer.questionId === question.questionId
																)?.selectedOption ===
																index + 1
															}
															onChange={() =>
																handleOptionChange(
																	question.questionId,
																	index + 1
																)
															}
														/>
														<label
															htmlFor={`${question.questionId}-${index}`}
															onClick={() =>
																handleOptionChange(
																	question.questionId,
																	index + 1
																)
															}
														>
															{option}
														</label>
													</div>
												))}
											</div>
										</div>
									))}
							</div>

							{currentpage === totalpages ? (
								<>
									<div className="examDetails-pagination">
										<button
											onClick={() => setCurrentpage(currentpage - 1)}
											disabled={currentpage === 1}
										>
											Previous
										</button>
									</div>

									<div className="examDetails-button">
										<button type="submit" disabled={notSubmitted}>
											{notSubmitted ? "Submitting..." : "Submit"}
										</button>
									</div>
								</>
							) : (
								<div className="examDetails-pagination">
									<button
										onClick={() => setCurrentpage(currentpage - 1)}
										disabled={currentpage === 1}
									>
										Previous
									</button>
									<button onClick={() => setCurrentpage(currentpage + 1)}>
										Next
									</button>
								</div>
							)}
						</form>
					</div>
				</main>
			)}
			<Footer />
		</>
	);
}
