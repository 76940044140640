// AddExam.js

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useCookies } from "react-cookie";
import BASE_URL from "../../subcomponents/Config";
import CloseIcon from "@mui/icons-material/Close";
import {
	TextField,
	Button,
	Typography,
	Container,
	Box,
	IconButton,
	CircularProgress,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import useFormValidation from "../../Hooks/useFormValidation";
import { toast, ToastContainer } from "react-toastify";
import CourseSelect from "../adminComponents/Api/CourseSelect";
import useQuestionFormValidation from "../../Hooks/useQuestionFormValidation";

const AdminLoginForm = styled("form")({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	border: "1px solid #ccc",
	padding: "20px",
	borderRadius: "5px",
});

const initialFormState = {
	name: "",
	courseId: "",
	questions: [
		{
			question: "",
			option1: "",
			option2: "",
			option3: "",
			option4: "",
			correctAnswer: "",
		},
	],
};

const AddQuestionBank = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [cookies] = useCookies(["adminToken", "adminRoles"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");

	const {
		formData,
		setFormData,
		setErrors,
		errors,
		handleChange,
		validateForm,
		handleQuestionChange,
		currentQuestionIndex,
		setCurrentQuestionIndex,
	} = useQuestionFormValidation(initialFormState);

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	const handleSelectCourse = (courseId) => {
		setFormData({ ...formData, courseId });

		setErrors((prevErrors) => ({
			...prevErrors,
			courseId: "",
		}));
	};
	const handleRemoveQuestion = (index) => {
		if (formData.questions.length > 1) {
			const updatedQuestions = [...formData.questions];

			updatedQuestions.splice(index, 1);

			setFormData({ ...formData, questions: updatedQuestions });

			setCurrentQuestionIndex(
				Math.min(currentQuestionIndex, updatedQuestions.length - 1)
			);
		} else {
			setFormData({
				...formData,
				questions: [
					{
						questionId: null,
						question: "",
						option1: "",
						option2: "",
						option3: "",
						option4: "",
						correctAnswer: "",
					},
				],
			});
		}
		setErrors({});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const validationErrors = validateForm(formData);

		if (Object.keys(validationErrors).length === 0) {
			setLoading(true);

			const formDataToSubmit = new FormData();
			formDataToSubmit.append("name", formData.name);
			formDataToSubmit.append("courseId", formData.courseId);

			formData.questions.forEach((question, index) => {
				formDataToSubmit.append(
					`questions[${index}].question`,
					question.question
				);
				formDataToSubmit.append(
					`questions[${index}].option1`,
					question.option1
				);
				formDataToSubmit.append(
					`questions[${index}].option2`,
					question.option2
				);
				formDataToSubmit.append(
					`questions[${index}].option3`,
					question.option3
				);
				formDataToSubmit.append(
					`questions[${index}].option4`,
					question.option4
				);
				formDataToSubmit.append(
					`questions[${index}].correctAnswer`,
					question.correctAnswer
				);
			});
			try {
				const response = await fetch(`${BASE_URL}/api/v1/questionBank/create`, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
					body: formDataToSubmit,
				});

				if (response.ok) {
					const courseData = await response.json();
					setLoading(false);
					setError("");
					toast.success(courseData.message);
					setCurrentQuestionIndex(0);
					setFormData(initialFormState);
				} else {
					const error = await response.json();
					setError(error.message);
					setLoading(false);
				}
			} catch (error) {
				console.log(error.message);
			}
		} else {
			setErrors(validationErrors);
		}
	};

	const addNewQuestion = () => {
		setFormData({
			...formData,
			questions: [
				...formData.questions,
				{
					question: "",
					option1: "",
					option2: "",
					option3: "",
					option4: "",
					correctAnswer: "",
				},
			],
		});
		setCurrentQuestionIndex(formData.questions.length);
	};

	const handlePreviousQuestion = () => {
		if (currentQuestionIndex > 0) {
			setCurrentQuestionIndex(currentQuestionIndex - 1);
			setErrors({});
		}
	};

	const handleNextQuestion = () => {
		if (currentQuestionIndex < formData.questions.length - 1) {
			setCurrentQuestionIndex(currentQuestionIndex + 1);
			setErrors({});
		}
	};

	return (
		<>
			<ToastContainer position="top-center" autoClose="2000" />
			<Container maxWidth="xs">
				<AdminLoginForm onSubmit={handleSubmit}>
					<Typography variant="h2" gutterBottom>
						Add Question Bank
					</Typography>
					{error && (
						<Typography variant="subtitle2" color="error" gutterBottom>
							{error}!!
						</Typography>
					)}
					<Box width="100%" marginBottom="20px">
						<TextField
							label="Name"
							variant="outlined"
							name="name"
							fullWidth
							value={formData.name}
							onChange={handleChange}
						/>
						{errors.name && <span style={{ color: "red" }}>{errors.name}</span>}
					</Box>

					<Box width="100%" marginBottom="20px">
						<CourseSelect
							selectedCourseId={formData.courseId}
							onSelectCourse={handleSelectCourse}
						/>
						{errors.courseId && (
							<span style={{ color: "red" }}>{errors.courseId}</span>
						)}
					</Box>

					<Box width="100%" marginBottom="20px">
						<Box style={{ display: "flex", justifyContent: "space-between" }}>
							<Typography variant="h6" gutterBottom>
								Question {currentQuestionIndex + 1} of{" "}
								{formData.questions.length}
							</Typography>
							<IconButton
								aria-label="delete"
								onClick={() => handleRemoveQuestion(currentQuestionIndex)}
							>
								<CloseIcon />
							</IconButton>
						</Box>

						<TextField
							label="Question"
							variant="outlined"
							name={`question`}
							fullWidth
							value={formData.questions[currentQuestionIndex].question}
							onChange={(e) => handleQuestionChange("question", e.target.value)}
						/>

						{errors[`question`] && (
							<span style={{ color: "red" }}>{errors[`question`]}</span>
						)}
					</Box>

					<Box style={{ display: "flex" }}>
						<Box width="100%" marginBottom="20px" marginRight="10px">
							<TextField
								label="Option 1"
								variant="outlined"
								name={`option1`}
								fullWidth
								value={formData.questions[currentQuestionIndex].option1}
								onChange={(e) =>
									handleQuestionChange("option1", e.target.value)
								}
							/>
							{errors[`option1`] && (
								<span style={{ color: "red" }}>{errors[`option1`]}</span>
							)}
						</Box>
						<Box width="100%" marginBottom="20px">
							<TextField
								label="Option 2"
								variant="outlined"
								name={`option2`}
								fullWidth
								value={formData.questions[currentQuestionIndex].option2}
								onChange={(e) =>
									handleQuestionChange("option2", e.target.value)
								}
							/>
							{errors[`option2`] && (
								<span style={{ color: "red" }}>{errors[`option2`]}</span>
							)}
						</Box>
					</Box>
					<Box style={{ display: "flex" }}>
						<Box width="100%" marginBottom="20px" marginRight="10px">
							<TextField
								label="Option 3"
								variant="outlined"
								name={`option3`}
								fullWidth
								value={formData.questions[currentQuestionIndex].option3}
								onChange={(e) =>
									handleQuestionChange("option3", e.target.value)
								}
							/>
							{errors[`option3`] && (
								<span style={{ color: "red" }}>{errors[`option3`]}</span>
							)}
						</Box>
						<Box width="100%" marginBottom="20px">
							<TextField
								label="Option 4"
								variant="outlined"
								name={`option4`}
								fullWidth
								value={formData.questions[currentQuestionIndex].option4}
								onChange={(e) =>
									handleQuestionChange("option4", e.target.value)
								}
							/>
							{errors[`option4`] && (
								<span style={{ color: "red" }}>{errors[`option4`]}</span>
							)}
						</Box>
					</Box>
					<Box width="100%" marginBottom="20px">
						<TextField
							label="Correct Answer"
							variant="outlined"
							name={`correctAnswer`}
							fullWidth
							value={formData.questions[currentQuestionIndex].correctAnswer}
							onChange={(e) =>
								handleQuestionChange("correctAnswer", e.target.value)
							}
						/>
						{errors[`correctAnswer`] && (
							<span style={{ color: "red" }}>{errors[`correctAnswer`]}</span>
						)}
					</Box>
					{errors.questions && (
						<span style={{ color: "red" }}>{errors.questions}</span>
					)}

					{/* Navigation Buttons */}
					<Box
						width="100%"
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						marginBottom="20px"
					>
						<IconButton
							color="primary"
							onClick={handlePreviousQuestion}
							disabled={currentQuestionIndex === 0}
						>
							<ArrowBack />
						</IconButton>
						<Button
							variant="outlined"
							color="secondary"
							disabled={loading}
							onClick={addNewQuestion}
							style={{ marginBottom: "20px" }}
						>
							Add More Questions
						</Button>
						<IconButton
							color="primary"
							onClick={handleNextQuestion}
							disabled={currentQuestionIndex === formData.questions.length - 1}
						>
							<ArrowForward />
						</IconButton>
					</Box>
					<Button type="submit" variant="contained" color="secondary">
						{loading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "6px",
								}}
							>
								<CircularProgress />
							</Box>
						) : (
							<Box>Add Question Bank</Box>
						)}
					</Button>
				</AdminLoginForm>
			</Container>
		</>
	);
};

export default AddQuestionBank;
