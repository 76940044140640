import React, { useState } from 'react'
import Path from "./Path";
import 'react-toastify/dist/ReactToastify.css';
import { Context } from './subcomponents/Context';
const App = () => {
  const [message, setMessage] = useState("");
  return (
    <div className='App'>
      <Context.Provider
      value={{
        message,
        setMessage,
      }}
      >
        <Path/>
      </Context.Provider>
    </div>
  )
}

export default App
