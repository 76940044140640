import { useState } from "react";

const useQuestionFormValidation = (initialFormState) => {
	const [formData, setFormData] = useState(initialFormState);
	const [errors, setErrors] = useState({});
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
		const validationErrors = validateField(name, value);
		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: validationErrors,
		}));
	};
	const handleQuestionChange = (field, value) => {
		const updatedQuestions = [...formData.questions];
		updatedQuestions[currentQuestionIndex][field] = value;
		setFormData({ ...formData, questions: updatedQuestions });
		const validationErrors = validateField(field, value);
		setErrors({
			...errors,
			[field]: validationErrors,
		});
	};
	const handleFileChange = (e) => {
		const { name, files } = e.target;
		const file = files[0];
		setFormData({
			...formData,
			[name]: file,
		});
		const validationErrors = validateField(name, file);
		setErrors({
			...errors,
			[name]: validationErrors,
		});
	};
	const validateField = (name, value) => {
		let error = "";

		if (name === "examName" && !value.trim()) {
			error = "Exam Name is required";
		} else if (name === "name" && !value.trim()) {
			error = "Name is required";
		} else if (name === "examType" && !value.trim()) {
			error = "Exam Type is required";
		} else if (name === "details" && !value.trim()) {
			error = "Exam Details are required";
		} else if (name === "courseId" && !value) {
			error = "Course selection is required";
		} else if (name === "totalTime" && !value) {
			error = "Total Time is required";
		} else if (name === "question" && !value.trim()) {
			error = "Question is required";
		} else if (name === "option1" && !value.trim()) {
			error = "Option 1 is required";
		} else if (name === "option2" && !value.trim()) {
			error = "Option 2 is required";
		} else if (name === "option3" && !value.trim()) {
			error = "Option 3 is required";
		} else if (name === "option4" && !value.trim()) {
			error = "Option 4 is required";
		} else if (name === "correctAnswer" && !value.trim()) {
			error = "Correct Answer is required";
		} else if (name === "file" && !value) {
			error = "Exam image is required";
		} else if (name === "questionIds" && value.length === 0) {
			error = "At least One question need to be selected.";
		}

		return error;
	};

	const validateForm = (formData) => {
		const errors = {};

		Object.keys(formData).forEach((field) => {
			const value = formData[field];
			if (Array.isArray(value)) {
				value.forEach((question) => {
					Object.keys(question).forEach((subField) => {
						const error = validateField(subField, question[subField]);
						if (error) errors[subField] = error;
					});
				});
			} else {
				const error = validateField(field, value);
				if (error) errors[field] = error;
			}
		});

		setErrors(errors);
		return errors;
	};

	return {
		formData,
		setFormData,
		errors,
		setErrors,
		handleChange,
		validateForm,
		handleQuestionChange,
		handleFileChange,
		currentQuestionIndex,
		setCurrentQuestionIndex,
	};
};

export default useQuestionFormValidation;
