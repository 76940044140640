import { useState } from "react";

const useFormValidation = (initialState) => {
  const [formData, setFormData] = useState(initialState);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "bannerUrls") {
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: name === "depositedAmount" ? Number(value) : value,
      }));
    }
    const validationErrors = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validationErrors,
    }));
  };
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    setFormData({
      ...formData,
      [name]: file,
    });
    const validationErrors = validateField(name, file);
    setErrors({
      ...errors,
      [name]: validationErrors,
    });
  };

  const handleFileChangePDF = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        pdfFile: "Please select a PDF file",
      }));
    } else if (file.type !== "application/pdf") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        pdfFile: "Invalid file type. Only PDF files are allowed.",
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, pdfFile: file }));
      setErrors((prevErrors) => ({ ...prevErrors, pdfFile: "" }));
    }
  };

  const handleFilesChange = (e) => {
    const { name, files } = e.target;
    const selectedFiles = Array.from(files);
    setFormData({
      ...formData,
      [name]: selectedFiles,
    });
    const validationErrors = validateField(name, files);
    setErrors({
      ...errors,
      [name]: validationErrors,
    });
  };

  const validateField = (fieldName, value) => {
    let fieldErrors = null;

    if (
      fieldName === "name" ||
      fieldName === "title" ||
      fieldName === "details" ||
      fieldName === "firstName" ||
      fieldName === "middleName" ||
      fieldName === "lastName" ||
      fieldName === "fatherName" ||
      fieldName === "grandFatherName" ||
      fieldName === "address" ||
      fieldName === "interestedCourse" ||
      fieldName === "jobDescription" ||
      fieldName === "message" ||
      fieldName === "categoryTitle" ||
      fieldName === "information" ||
      fieldName === "examName" ||
      fieldName === "courseTitle" ||
      fieldName === "remarks" ||
      fieldName === "duration" ||
      fieldName === "courseId" ||
      fieldName === "userName" ||
      fieldName === "name" ||
      fieldName === "username"

      // fieldName === "courseEnrollmentRequest"
    ) {
      if (typeof value === "string") {
        fieldErrors = !value.trim() ? `${fieldName} is required` : null;
      }
    } else if (fieldName === "meetingLink" || fieldName === "vacancyUrl") {
      fieldErrors = null;
    } else if (fieldName === "mobileNumber") {
      fieldErrors =
        !value.trim() || !/^9[0-9]{9}$/.test(value)
          ? `${fieldName} must be 10 digit and start with 9`
          : null;
    } else if (
      fieldName === "password" ||
      fieldName === "newPassword" ||
      fieldName === "oldPassword"
    ) {
      fieldErrors =
        !value.trim() || !/^(?=.{8,})/.test(value)
          ? `${fieldName} should be 8 characters`
          : null;
    } else if (fieldName === "confirmPassword") {
      if (!value.trim() || !/^(?=.{8,})/.test(value))
        fieldErrors = `${fieldName} should be 8 characters`;
      else if (formData.password) {
        if (value !== formData.password) {
          fieldErrors = "Password and Confirm Password must be same.";
        }
      } else if (formData.newPassword) {
        if (value !== formData.newPassword) {
          fieldErrors = "New Password and Confirm Password must be same.";
        }
      } else fieldErrors = null;
    } else if (fieldName === "email" || fieldName === "applyEmail") {
      fieldErrors =
        !value.trim() || !/\S+@\S+\.\S+/.test(value)
          ? "email is invalid"
          : null;
    } else if (fieldName === "courseFee" || fieldName === "depositedAmount") {
      fieldErrors = value <= 0 ? `${fieldName} is required` : null;
    } else if (
      fieldName === "roles" ||
      fieldName === "files" ||
      fieldName === "images" ||
      fieldName === "bannerImageFiles" ||
      fieldName === "questionIds" ||
      fieldName === "teacherIds"
    ) {
      fieldErrors =
        value.length === 0 ? `At least one ${fieldName} is required` : null;
    } else if (
      fieldName === "expirationDate" ||
      fieldName === "weeklyExamDate"
    ) {
      const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
      fieldErrors = !dateRegex.test(value)
        ? "Date must be of correct format"
        : null;
    } else {
      fieldErrors = value === null ? `${fieldName} is required` : null;
    }
    return fieldErrors;
  };

  const validateForm = (data) => {
    let errors = {};
    for (let field in initialState) {
      const fieldErrors = validateField(field, data[field]);
      if (fieldErrors) {
        errors[field] = fieldErrors;
      }
    }

    return errors;
  };

  return {
    formData,
    setFormData,
    errors,
    setErrors,
    handleChange,
    handleFileChange,
    handleFilesChange,
    validateForm,
    handleFileChangePDF,
  };
};

export default useFormValidation;
