import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import { styled } from "@mui/material/styles";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Chip,
	CircularProgress,
	Dialog,
	DialogTitle,
	DialogContent,
	Button,
	TextField,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "../adminComponents/Pagination/Pagination";
import SearchBar from "../../pages/components/Search";
import useFormValidation from "../../Hooks/useFormValidation";

const PaymentForm = styled("form")({
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "5px",
	borderRadius: "5px",
});

const initialFormState = {
	duration: "",
};

export default function EnrollRequestList() {
	const [users, setUsers] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const nav = useNavigate();

	const [open, setOpen] = useState(false);
	const [courseId, setCourseId] = useState("");
	const [userId, setUserId] = useState("");

	const [cookies] = useCookies(["adminToken"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	const [searchError, setSearchError] = useState("");

	const {
		formData,
		setFormData,
		setErrors,
		errors,
		handleChange,
		validateForm,
	} = useFormValidation(initialFormState);
	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	useEffect(() => {
		getData();
	}, [currentPage]);

	function handleDetails(id) {
		nav(`/admin/ADMISSION_DEPARTMENT/course/details/${id}`);
	}

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/enrollmentRequest/requests?page=${currentPage}&size=${itemsPerPage}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
				}
			);
			if (response.ok) {
				const apiData = await response.json();
				setUsers(apiData.request);
				setTotalPages(apiData.totalPages);
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const handleSearch = async (searchTerm) => {
		if (searchTerm === "") {
			getData();
			setSearchError("");
		} else {
			try {
				const res = await fetch(
					`${BASE_URL}/api/v1/user/search?name=${searchTerm}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${adminToken}`,
						},
					}
				);
				if (!res.ok) {
					const error = await res.json();
					setSearchError(error.message);
					setUsers([]);
				} else {
					const data = await res.json();
					setUsers(data);
					setSearchError("");
				}
			} catch (error) {
				console.log(error.message);
			}
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const validationErrors = validateForm(formData);
		console.log(errors);
		if (Object.keys(validationErrors).length === 0) {
			const data = new FormData();
			data.append("duration", formData.duration);

			setLoading(true);
			try {
				const response = await fetch(
					`${BASE_URL}/api/v1/course/${courseId}/enroll/user/${userId}`,
					{
						method: "POST",
						headers: {
							Authorization: `Bearer ${adminToken}`,
							"Content-Type": "application/json",
						},
						body: JSON.stringify(formData),
					}
				);

				if (response.ok) {
					const { message } = await response.json();
					toast.success(message);
					setCourseId("");
					setFormData(initialFormState);
					handleClose();
					getData();
				} else {
					const error = await response.json();
					toast.error(error.message);
				}
			} catch (error) {
				console.log("An error occurred:", error.message);
				setError("An unexpected error occurred. Please try again.");
			} finally {
				setLoading(false);
			}
		} else {
			setErrors(validationErrors);
		}
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const handleOpen = (user, course) => {
		setOpen(true);
		setCourseId(course);
		setUserId(user);
	};

	const handleClose = () => {
		setOpen(false);
		setFormData(initialFormState);
		setErrors({});
	};

	const pageNumbers = [];
	for (let i = 1; i <= totalPages; i++) {
		pageNumbers.push(i);
	}

	return (
		<Box>
			<ToastContainer position="top-center" autoClose="2000" />
			<Card variant="outlined">
				<CardContent>
					<Box sx={{ display: "flex", justifyContent: "space-between" }}>
						<Typography variant="h3">Enroll Request List</Typography>
						<Box>
							<SearchBar onSearch={handleSearch} />
							{searchError ? (
								<Box style={{ fontSize: "0.8rem", color: "red" }}>
									{searchError}
								</Box>
							) : null}
						</Box>
					</Box>

					<Box
						sx={{
							overflow: {
								xs: "auto",
								sm: "unset",
							},
						}}
					>
						<Table
							aria-label="simple table"
							sx={{
								mt: 3,
							}}
						>
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Name
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Email
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Mobile Number
										</Typography>
									</TableCell>

									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Requested Date
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Course Title
										</Typography>
									</TableCell>

									<TableCell align="center">
										<Typography color="textSecondary" variant="h5">
											Course Details
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography color="textSecondary" variant="h5">
											Enroll
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{users &&
									users.map((user) => (
										<TableRow key={user.id}>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
														paddingLeft: "0",
													}}
												>
													{user.firstName} {user.lastName}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
														paddingLeft: "0",
													}}
												>
													{user.email}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
														paddingLeft: "0",
													}}
												>
													{user.mobileNumber}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
														paddingLeft: "0",
													}}
												>
													{user.requestDate}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
														paddingLeft: "0",
													}}
												>
													{user.courseTitle}
												</Typography>
											</TableCell>

											<TableCell align="center">
												<Chip
													sx={{
														pl: "4px",
														pr: "4px",
														backgroundColor: "success.main",
														color: "#fff",
													}}
													size="small"
													label="Details"
													onClick={() => handleDetails(user.courseId)}
												/>
											</TableCell>
											<TableCell align="center">
												<Chip
													sx={{
														pl: "4px",
														pr: "4px",
														backgroundColor: "secondary.main",
														color: "#fff",
													}}
													size="small"
													label="Enroll"
													onClick={() => handleOpen(user.userId, user.courseId)}
												/>
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
						{loading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								<CircularProgress />
							</Box>
						) : null}
						{error ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								{error}
							</Box>
						) : null}
						<Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={handlePageChange}
						/>
					</Box>
				</CardContent>
			</Card>
			<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
				<DialogTitle>Enroll To Course</DialogTitle>
				<DialogContent>
					{loading ? (
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							height="200px"
						>
							<CircularProgress />
						</Box>
					) : (
						<Box component="form" onSubmit={handleSubmit}>
							<TextField
								fullWidth
								label="Duration (in days)"
								name="duration"
								value={formData.duration}
								onChange={handleChange}
								error={!!errors.duration}
								helperText={errors.duration}
								type="Number"
								variant="outlined"
								placeholder="Enter course duration"
								sx={{ mb: 3 }}
							/>

							<Button
								disabled={!courseId}
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								sx={{
									padding: "12px 0",
									fontSize: "16px",
									fontWeight: "bold",
								}}
							>
								Enroll
							</Button>
						</Box>
					)}
				</DialogContent>
			</Dialog>
		</Box>
	);
}
