import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Chip,
  //   CircularProgress,
  Button,
} from "@mui/material";
// import Pagination from "../adminComponents/Pagination/Pagination";
// import TruncateText from "../adminComponents/Truncate/TruncateText";
import { toast, ToastContainer } from "react-toastify";
import ConfirmDialog from "../adminComponents/DialogBox/Confirm";

export default function QuestionBankDetail() {
  const nav = useNavigate();
  const { id } = useParams();
  const [questionbank, setQuestionBank] = useState({});
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState("");
  const [cookies] = useCookies(["adminToken"]);
  const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
  useEffect(() => {
    setAdminToken(cookies.adminToken || "");
  }, [cookies]);

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    getQuestions();
  }, []);

  function Back() {
    nav(-1);
  }
  const handleEdit = async (id) => {
    nav(`/admin/EXAM_DEPARTMENT/questionBank/edit/${id}`);
  };

  const handleOpenDialogQuestions = (id) => {
    setDialogOpen(true);
    setDeleteId(id);
    setDeleteName("questions");
  };
  const handleOpenDialogQuestionBank = () => {
    setDialogOpen(true);
    setDeleteName("questionbank");
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDeleteId(null);
    setDeleteName("");
  };

  const getData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${BASE_URL}/api/v1/questionBank/detail/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        }
      );
      if (response.ok) {
        const apiData = await response.json();
        setQuestionBank(apiData.questionBank);
        setLoading(false);
      } else {
        const error = await response.json();
        setError(error.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getQuestions = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${BASE_URL}/api/v1/questionBank/${id}/questions`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        }
      );
      if (response.ok) {
        const apiData = await response.json();
        setQuestions(apiData.questions);
        setLoading(false);
      } else {
        const error = await response.json();
        setError(error.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleDelete = async () => {
    setLoading(true);
    if (deleteName === "questionbank") {
      try {
        const response = await fetch(
          `${BASE_URL}/api/v1/questionBank/delete/${id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${adminToken}`,
            },
          }
        );

        if (response.ok) {
          handleCloseDialog();
          Back();
          setLoading(false);
        } else {
          handleCloseDialog();
          const error = await response.json();
          setError(error.message);
          setLoading(false);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    } else if (deleteName === "questions") {
      try {
        const response = await fetch(
          `${BASE_URL}/api/v1/questionBank/deleteQuestion/${deleteId}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${adminToken}`,
            },
          }
        );
        if (response.ok) {
          handleCloseDialog();
          const apiData = response.json();
          setQuestions(apiData.questions);
          getQuestions();
          toast.success(apiData.message);
          setLoading(false);
        } else {
          handleCloseDialog();
          const error = await response.json();
          setLoading(false);
          setError(error.message);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      } finally {
        getQuestions();
      }
    }
  };

  return (
    <Box>
      <ToastContainer position="top-center" autoClose="2000" />
      <ConfirmDialog
        open={dialogOpen}
        title="Confirm Action"
        content="Are you sure you want to delete this?"
        onClose={() => handleCloseDialog()}
        onConfirm={() => handleDelete()}
      />
      <Button
        onClick={() => Back()}
        variant="outlined"
        color="secondary"
        style={{ marginBottom: "0.2rem" }}
      >
        Back
      </Button>
      <Card variant="outlined">
        <CardContent>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h3">Question Bank Details</Typography>
            <Box>
              <Chip
                sx={{
                  pl: "4px",
                  pr: "4px",
                  mr: "10px",
                  backgroundColor: "primary.main",
                  color: "#fff",
                  fontSize: "0.9rem",
                }}
                size="medium"
                label="Edit"
                onClick={() => handleEdit(id)}
              />
              <Chip
                sx={{
                  pl: "4px",
                  pr: "4px",
                  backgroundColor: "error.main",
                  color: "#fff",
                  fontSize: "0.9rem",
                }}
                size="medium"
                label="Delete"
                onClick={() => handleOpenDialogQuestionBank()}
              />
            </Box>
          </Box>
          <Box
            sx={{
              xs: "auto",
              sm: "unset",
              overflowX: "auto",
            }}
          >
            <Table
              aria-label="simple table"
              sx={{
                mt: 3,
              }}
            >
              <TableBody>
                {questionbank && (
                  <>
                    <TableRow>
                      <TableCell>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: "500",
                          }}
                        >
                          Name
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: "500",
                          }}
                        >
                          {questionbank.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: "500",
                          }}
                        ></Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: "500",
                          }}
                        >
                          Course Id
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: "500",
                          }}
                        >
                          {questionbank.courseId}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: "500",
                          }}
                        ></Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          xs={{
                            fontSize: "15px",
                            fontWeight: "500",
                          }}
                        >
                          Course Title
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          style={{
                            width: "20rem",
                            height: "auto",
                          }}
                        >
                          {questionbank.courseTitle}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: "500",
                          }}
                        ></Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          xs={{
                            fontSize: "15px",
                            fontWeight: "500",
                          }}
                        >
                          Total Questions
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          style={{
                            width: "20rem",
                            height: "auto",
                          }}
                        >
                          {questionbank.totalQuestions}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: "500",
                          }}
                        ></Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          xs={{
                            fontSize: "15px",
                            fontWeight: "500",
                          }}
                        >
                          Created Date
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          style={{
                            width: "20rem",
                            height: "auto",
                          }}
                        >
                          {questionbank.createdDate}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: "500",
                          }}
                        ></Typography>
                      </TableCell>
                    </TableRow>
                  </>
                )}

                <TableRow>
                  <TableCell>
                    <Typography color="textSecondary" variant="h5">
                      QuestionId
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="h5">
                      Question Details
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography color="textSecondary" variant="h5">
                      Created Date
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography color="textSecondary" variant="h5">
                      Delete
                    </Typography>
                  </TableCell>
                </TableRow>
                {questions &&
                  questions.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        <Typography>{data.questionId}</Typography>
                      </TableCell>

                      <TableCell align="left">
                        <Typography sx={{ paddingLeft: "0" }}>
                          <Box>Question: {data.question}</Box>
                          <Box>Option 1: {data.option1}</Box>
                          <Box>Option 2: {data.option2}</Box>
                          <Box>Option 3: {data.option3}</Box>
                          <Box>Option 4: {data.option4}</Box>
                          <Box>Correct Answer: {data.correctAnswer}</Box>
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{ fontSize: "15px", fontWeight: "500" }}
                      >
                        <Typography sx={{ paddingLeft: "0" }}>
                          {data.createdDate}
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        <Chip
                          sx={{
                            pl: "4px",
                            pr: "4px",
                            backgroundColor: "error.main",
                            color: "#fff",
                          }}
                          size="small"
                          label="Delete"
                          onClick={() =>
                            handleOpenDialogQuestions(data.questionId)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
