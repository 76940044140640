const BASE_URL = "https://kosish.com";

//Local Server
//http://192.168.1.118:8081

//Test Server
//http://3.109.243.255:8081

//Production Server
//http://3.111.165.178:8081

//Domain
//https://kosisheducation.com:8081

//Domain2
//https://kosish.com

export default BASE_URL;
