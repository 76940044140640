import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import { IoCall } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import "../Styles/contact.scss";

import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import useFormValidation from "../Hooks/useFormValidation";
import BASE_URL from "../subcomponents/Config";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import MapComponent from "./components/MapComponent";

// Fix Leaflet's default icon issue with React
// L.Icon.Default.mergeOptions({
// 	iconRetinaUrl: markerIcon2x,
// 	iconUrl: markerIcon,
// 	shadowUrl: markerShadow,
// });
const initialFormState = {
	name: "",
	email: "",
	mobileNumber: "",
	message: "",
};

const Contact = () => {
	const nav = useNavigate();
	const [error, setError] = useState("");
	const [cookies] = useCookies(["userToken"]);
	const [token, setToken] = useState(cookies.userToken || "");

	const {
		formData,
		setFormData,
		setErrors,
		errors,
		handleChange,
		validateForm,
	} = useFormValidation(initialFormState);

	useEffect(() => {
		setToken(cookies.userToken || "");
	}, [cookies]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (token) {
			const validationErrors = validateForm(formData);
			if (Object.keys(validationErrors).length === 0) {
				try {
					const response = await fetch(`${BASE_URL}/api/v1/createContact`, {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
						body: JSON.stringify(formData),
					});
					if (response.ok) {
						const data = await response.json();
						// alert(data.message);
						toast.success(data.message);
						setFormData(initialFormState);
					} else {
						const errorData = await response.json();
						setError(errorData.message);
					}
				} catch (error) {
					setError(error.message);
				}
			} else {
				setErrors(validationErrors);
			}
		} else {
			nav("/login");
		}
	};
	return (
		<>
		<Navbar />
		<ToastContainer position="top-center" />
		<main>
			<div className="contact-main">
				<h3>Contact Us </h3>
				<div className="contact-text">
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente
					debitis alias amet magnam laboriosam sequi placeat porro dignissimos
					rem cupiditate modi reiciendis eius natus ea, enim voluptatem
					provident iure soluta. Lorem ipsum dolor sit amet consectetur
					adipisicing elit. Quos vero commodi aspernatur impedit consequatur est
					ex nisi, quas eum, veritatis repellat at quis consectetur fugit aut
					eveniet dolore dignissimos architecto!
				</div>
				<div className="contact-submain">
					<div className="contact-submain-inner">
						<div className="contact-submain-icon" id="call-icon">
							<IoCall />
						</div>
						<div className="contact-submain-text">+977-986153489</div>
						<div className="contact-submain-text">+977-9851172194</div>
					</div>
					<div className="contact-submain-inner">
						<div className="contact-submain-icon" id="location-icon">
							<FaLocationDot />
						</div>
						<div className="contact-submain-text">
							New Baneshwor, Kathmandu, Nepal
						</div>
					</div>
					<div className="contact-submain-inner">
						<div className="contact-submain-icon" id="email-icon">
							<MdEmail />
						</div>
						<div className="contact-submain-text">koshish73@gmail.com</div>
					</div>
				</div>
				<div className="contact-map">
					<h4>View on Map</h4>
					{/* <MapContainer
						center={position}
						zoom={18}
						scrollWheelZoom={false}
						style={{ height: "400px", width: "100%" }}
					>
						<TileLayer
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
						/>
						<Marker position={position}>
							<Popup>Our Office Location</Popup>
						</Marker>
					</MapContainer> */}
					<MapComponent />
				</div>
				<div className="contact-below">
					<h3>Contact Us</h3>
					<form onSubmit={handleSubmit}>
						<div className="form-top">
							<div className="form-input">
								<input
									type="text"
									name="name"
									value={formData.name}
									placeholder="Enter your Name"
									onChange={handleChange}
								/>
								{errors.name && <div className="errors">{errors.name}</div>}
							</div>
							<div className="form-input">
								<input
									type="text"
									name="email"
									value={formData.email}
									placeholder="Enter your Email Address"
									onChange={handleChange}
								/>
								{errors.email && <div className="errors">{errors.email}</div>}
							</div>
							<div className="form-input">
								<input
									type="number"
									name="mobileNumber"
									value={formData.mobileNumber}
									placeholder="Enter your Phone Number"
									onChange={handleChange}
								/>
								{errors.mobileNumber && (
									<div className="errors">{errors.mobileNumber}</div>
								)}
							</div>
						</div>
						<div className="form-below">
							<textarea
								type="text"
								name="message"
								value={formData.message}
								placeholder="Your Message Here"
								onChange={handleChange}
							/>
							{errors.message && (
								<span className="errors">{errors.message}</span>
							)}
						</div>
						{error && <div className="errors">{error}</div>}
						<div className="submit-button">
							<button type="submit">Send Message</button>
						</div>
					</form>
				</div>
			</div>
		</main>
		<Footer />
		</>
	);
};

export default Contact;
