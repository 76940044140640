import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.jsx";
import "../../Styles/Exam/examResult.scss";
import scoreimage from "../components/Images/score-image.png";
import { useLocation } from "react-router-dom";
import Footer from "../components/Footer.jsx";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import { Box, CircularProgress } from "@mui/material";
import useLoading from "../../Hooks/useLoading.js";
import Loading from "../components/Loading.jsx";

export default function Result() {
	const { id } = useParams();
	const location = useLocation();
	const { loading, showLoading, hideLoading } = useLoading();
	const [cookies] = useCookies(["userToken", "userId"]);
	const [error, setError] = useState("");
	const [token, setToken] = useState(cookies.userToken || "");
	const [data, setData] = useState(location.state ? location.state.result : "");
	const answer = data.answers;
	const [userid, setUserId] = useState(cookies.userId || "");
	const wronganswer = data.totalQuestions - data.totalMarks;
	const timetaken = String(data.timeTaken);
	const [hours, minutes, seconds] = timetaken
		.split(":")
		.map((val) => parseInt(val.trim(), 10));

	useEffect(() => {
		setToken(cookies.userToken || "");
		setUserId(cookies.userId || "");
	}, [cookies]);

	useEffect(() => {
		resultdata();
		window.scrollTo(0, 0);
	}, []);

	const resultdata = async () => {
		if (data === "") {
			showLoading();
			try {
				const response = await fetch(
					`${BASE_URL}/api/v1/exam/${id}/user/${userid}/studentAnswers`,
					{
						method: "GET",
						headers: { Authorization: `Bearer ${token}` },
					}
				);
				if (response.ok) {
					const data = await response.json();
					setData(data.data);
					hideLoading();
				} else {
					const error = await response.json();
					setError(error.message);
					hideLoading(false);
				}
			} catch (error) {
				showLoading();
			}
		}
	};

	return (
		<>
			<Navbar />
			{loading ? <Loading /> : null}
			{!loading && (
				<main>
					<div className="show-result">
						<div className="result-image">
							<img src={scoreimage} alt="score-image" />
						</div>
						<div className="result-score">
							<div className="score">
								<span style={{ color: "#FD6A02" }}>{data.totalMarks}</span>/
								{data.totalQuestions}
							</div>
							<div className="score-text">
								Well done, You can do lot better{" "}
							</div>
							{/* <button className="try-again">Try Again</button> */}
						</div>
						<div className="result-details">
							<div className="result-details-content">
								<p className="result-details-content-p">
									Total Time Consumed:{" "}
									<span>
										{hours !== 0 && (
											<>
												{hours} <span> hrs</span>{" "}
											</>
										)}
										{minutes !== 0 && (
											<>
												{minutes} <span> mins</span>{" "}
											</>
										)}
										{seconds && (
											<>
												{seconds} <span> secs</span>
											</>
										)}
									</span>
								</p>
								<p className="result-details-content-p">
									Your Marks: <span>{data.totalMarks}</span>
								</p>
								<p className="result-details-content-p">
									Full Marks: <span>{data.totalQuestions}</span>
								</p>

								<p className="result-details-content-p">
									Correct Answer: <span>{data.totalMarks}</span>
								</p>
								<p className="result-details-content-p">
									Wrong Answer:{" "}
									<span>{isNaN(wronganswer) ? "" : wronganswer}</span>
								</p>
							</div>
						</div>
					</div>

					<div className="answer-check">
						<p className="exam-title">Check Your Answer</p>

						{answer &&
							answer.map((Answer, index) => (
								<div key={index} className="question-answer">
									<p className="question">
										<span
											style={{
												marginRight: "20px",
											}}
										>
											{index + 1}.
										</span>
										{Answer.question}
									</p>
									<p className="actual-answer">
										<span style={{ marginRight: "20px", fontWeight: 600 }}>
											Actual Answer:
										</span>
										<span
											style={{
												marginRight: "20px",
												color: "#0DA900",
											}}
										>
											{Answer.correctAnswer}
										</span>
									</p>
									<p className="your-answer">
										<span style={{ marginRight: "35px", fontWeight: 600 }}>
											Your Answer:
										</span>
										<span
											style={{
												color: Answer.isCorrect ? "#0DA900" : "#FE0100",
												marginRight: "20px",
											}}
										>
											{Answer.selectedOptionValue}
										</span>
									</p>
									<hr className="solid" />
								</div>
							))}
					</div>
				</main>
			)}
			<Footer />
		</>
	);
}
